import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import TwoColDisplay from "../components/two-col-display"

import { graphql } from "gatsby"

const ContactPage = ({ data }) => {
  const image = data.file.childImageSharp.gatsbyImageData

  return (
    <Layout>
      <Seo title="Contact" />

      <div className="grid grid-cols-1 items-center justify-center w-full pb-24">
        <div className="pt-4 flex flex-grow justify-center items-center">
          <div className="max-w-6xl">
            <TwoColDisplay image={image}>
              <div className="font-montserrat text-xl text-center p-4">
                <h1 className="font-rubik text-3xl">Contact Us</h1>
                <div>sterlingtattooclub@gmail.com</div>
                <div>(603) 856-8148</div>
                <br></br>
                <div>18 Kennedy Ln</div>
                <div>Concord, NH, 03301</div>
              </div>
            </TwoColDisplay>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  query MyQuery {
    file(name: { eq: "contact-hero" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
